/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(window).on("load resize", function equalHeights() {
          $('.row').each(function(){
            var highestBox = 0;
            $(this).find('.match-height').css("min-height", 0);
            $(this).find('.match-height').each(function() {
              if ($(this).outerHeight() > highestBox) {
                highestBox = $(this).outerHeight();
              }
            });
            $(this).find('.match-height').css("min-height", highestBox);
          });
        }).resize();

        function createMoreMenu(menu, width) {
          if($(window).width() > width) { //get body width without(!) scrollbar
            var w = 0;
            var mw = $(menu).parent().width() - 260; //100 = width of 'more' item
            var menuhtml = '';
            $(menu).children().each(function() {
              w += $(this).outerWidth(true);
              if (mw < w && !$(this).find('a').hasClass('more')) {
                menuhtml += $('<div>').append($(this).clone()).html();
                $(this).remove();
              }
            });
            if(menuhtml) {
              $(menu + " .dropdown.more>ul").html('');
              $(menu + " .dropdown.more>ul").append(menuhtml);
              $(menu + " .dropdown.more").removeClass('hidden-lg-up').removeClass('hidden-lg-down');
            }
          }
        }

        $('header ul.more').each(function() {
          var menu = '#' + $(this).attr('id');
          var width = $(this).data('breakpoint') || 1025;
          var originalMenu = $(menu).clone();
          createMoreMenu(menu, width); $(menu).removeClass('vh'); $(originalMenu).removeClass('vh');
          $(window).on("load resize", function() { $(menu).replaceWith(originalMenu.clone()); createMoreMenu(menu, width); });
        });

        $('.testimonials__slideshow').flickity({
          contain: true,
          wrapAround: true,
          dragThreshold: 10,
          cellAlign: 'center'
        });
        $('.featured-slideshow').flickity({
          contain: true,
          wrapAround: true
        });
        $('.gallery').flickity({
          contain: true,
          wrapAround: true,
          cellAlign: 'left'
        });
        new WOW().init();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(".nav-toggle").click(function(event) {
            var scrollHeight = $(window).scrollTop();
            $(".nav-wrapper, .nav-toggle, body").toggleClass("open");
            event.preventDefault();
            if($('.nav-toggle').hasClass('open')) {
              $('.nav-toggle').attr('data-height', scrollHeight);
            } else {
              $(window).scrollTop($('.nav-toggle').attr('data-height'));
            }
        });

        $(".archive .wblock").stick_in_parent({'offset_top':140}); //all +90 (sheader)
        $(".contact .wblock").stick_in_parent({'offset_top':110});
        $(".arrangement .gblock").stick_in_parent({'offset_top':110});
        $(".text-page .cta").stick_in_parent({'offset_top':110});

        $('.gfield_checkbox li, .gfield_checkbox li label').click(function() {
          checkbox = $('.gfield_checkbox li').find('input');
          checkbox.prop("checked", !checkbox.prop("checked"));
          $('.gfield_checkbox li').toggleClass('active');
        });

        if ( $( '.events' ).length ) {

					$( '.months li a' ).on( 'click', function ( e ) {
						e.preventDefault();
            $( '.months li a, .events__single' ).removeClass( 'active' );
						$(this).addClass( 'active' );
						$( '.' + $( this ).data( 'target' ) ).addClass( 'active' );
					});

          if($('.events .months').length > 1 ){

            var index = $('.events .months[data-year="'+ $('#currentyear').val() +'"]').index();
            $('.months-slideshow').flickity({
              contain: true,
              initialIndex: index,
              cellAlign: 'left'
            });

          }
				}
        $(window).on('load', function() {
          if($(window).width() < 768) {
            $('.collapse').collapse('hide');
          }
        });
        $('#accordion').on('shown.bs.collapse', function (e) {
          var target = $('#' + e.target.id).parent();
          console.log('SCROLLL!!', target);
          if (target.length) {
            console.log(target.offset().top);
            $('html,body').animate({
              scrollTop: target.offset().top - 110
            }, 500);
            //return false;
          }
        });
      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page
        (function($) {

        function new_map( $el ) {

        	// var
        	var $markers = $el.find('.marker');

        	// vars
        	var args = {
        		zoom		: 16,
        		center		: new google.maps.LatLng(0, 0),
        		mapTypeId	: google.maps.MapTypeId.ROADMAP,
        		scrollwheel: false,
            disableDefaultUI: true,
            zoomControl: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: false
        	};

        	// create map
        	var map = new google.maps.Map( $el[0], args);


        	// add a markers reference
        	map.markers = [];


        	// add markers
        	$markers.each(function(){
            	add_marker( $(this), map );
        	});

        	center_map( map );
        	return map;
        }

        function add_marker( $marker, map ) {

        	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        	var marker = new google.maps.Marker({
        		position	: latlng,
        		map			: map
        	});

        	// add to array
        	map.markers.push( marker );

        	// if marker contains HTML, add it to an infoWindow
        	if( $marker.html() )
        	{
        		// create info window
        		var infowindow = new google.maps.InfoWindow({
        			content		: $marker.html()
        		});

        		// show info window when marker is clicked
        		google.maps.event.addListener(marker, 'click', function() {
        			infowindow.open( map, marker );
        		});
        	}

        }

        function center_map( map ) {

        	// vars
        	var bounds = new google.maps.LatLngBounds();
        	$.each( map.markers, function( i, marker ){

        		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

        		bounds.extend( latlng );

        	});

        	// only 1 marker?
        	if( map.markers.length == 1 )
        	{
        		// set center of map
        	    map.setCenter( bounds.getCenter() );
        	    map.setZoom( 15 );
        	}
        	else
        	{
        		// fit to bounds
        		map.fitBounds( bounds );
        	}

        }
        var map = null;

        $(document).ready(function(){
        	$('.acf-map').each(function(){
        		map = new_map( $(this) );
        	});
        });
        })(jQuery);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
